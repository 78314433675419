import DividedAutocompleteItem from "../models/divided-autocomplete-item";
import DividedAutocompleteOptions from "../models/divided-autocomplete-options";

const defaults: DividedAutocompleteOptions = {
  threshold: 4,
  maximumItems: 5,
  highlightTyped: true,
  highlightClass: "text-primary",
};

function createItem(lookup: string, item: DividedAutocompleteItem, opts: DividedAutocompleteOptions): string {
  let label: string;
  if (opts.highlightTyped) {
    const idx = item.label.toLowerCase().indexOf(lookup.toLowerCase());
    label =
      item.label.substring(0, idx) +
      '<span class="' +
      expandClassArray(opts.highlightClass ?? "") +
      '">' +
      item.label.substring(idx, idx + lookup.length) +
      "</span>" +
      item.label.substring(idx + lookup.length, item.label.length);
  } else {
    label = item.label;
  }
  let iconHtml: string = "";
  if (item.iconClass) {
    iconHtml = `<i class="${item.iconClass}" />`;
  }
  return `<button type="button" class="dropdown-item" data-ac-value="${encodeURIComponent(
    item.value,
  )}" data-ac-label="${encodeURIComponent(item.label)}">${label} ${iconHtml}</button>`;
}

function createItems(this: any, field: HTMLInputElement, opts: DividedAutocompleteOptions) {
  const lookup = field.value.trim();
  if (lookup.length < (opts.threshold ?? 0)) {
    clearAutocomplete(field);
    return 0;
  }

  const element = field.nextElementSibling as HTMLElement;
  element.innerHTML = "";

  let count = 0;
  const source = opts.source ?? {};
  const categoryNames = Object.keys(source);
  for (let i = 0; i < categoryNames.length; i++) {
    const categoryName = categoryNames[i];
    const category = source[categoryName];
    if (category.length <= 0) {
      continue;
    }

    if (i > 0 && source[categoryNames[i - 1]].length > 0) {
      element.innerHTML += `<div class="dropdown-divider"></div>`;
    }

    element.innerHTML += `<h6 class="dropdown-header">${categoryName}</h6>`;
    for (let j = 0; j < category.length; j++) {
      const item = category[j] as DividedAutocompleteItem;
      if (item.label.toLowerCase().indexOf(lookup.toLowerCase()) >= 0) {
        element.innerHTML += createItem(lookup, item, opts);
        const maxItems = opts.maximumItems ?? 0;
        if (maxItems > 0 && ++count >= maxItems) {
          break;
        }
      }
    }
  }

  // Option action
  element.querySelectorAll(".dropdown-item").forEach((item) => {
    item.addEventListener("click", function () {
      field.value = (item as HTMLElement).textContent ?? "";
      if (opts.onSelectItem) {
        opts.onSelectItem(
          decodeURIComponent((item as HTMLElement).dataset.acValue ?? ""),
          decodeURIComponent((item as HTMLElement).dataset.acLabel ?? ""),
          field,
        );
      }
    });
  });
  document.addEventListener("click", (event: MouseEvent) => {
    const dropdownMenu = field.parentElement?.querySelector(".dropdown-menu.d-block");
    const clickWasOutsideSearch = !field.contains(event.target as Node);
    if (clickWasOutsideSearch && (!dropdownMenu || !dropdownMenu.contains(event.target as Node))) {
      clearAutocomplete(field);
    }
  });
  return element.children.length;
}

export const clearAutocomplete = function (el: HTMLInputElement) {
  // Clear previously set autocomplete
  el.parentElement?.classList.remove("dropdown");
  el.removeAttribute("data-toggle");
  el.classList.remove("dropdown-toggle");
  const dropdownMenu = el.parentElement?.querySelector(".dropdown-menu.d-block");
  if (dropdownMenu) {
    dropdownMenu.remove();
  }
};

const autocompleteWithDividers = function (el: HTMLInputElement, options: DividedAutocompleteOptions) {
  // Merge options with default
  let opts: DividedAutocompleteOptions = { ...defaults, ...options };
  let _field = el;

  // Clear previously set autocomplete
  clearAutocomplete(el);

  // Attach dropdown
  _field.parentElement?.classList.add("dropdown");
  _field.setAttribute("data-toggle", "dropdown");
  _field.classList.add("dropdown-toggle");
  const dropdown = document.createElement("div");
  dropdown.className = "dropdown-menu d-block";
  // Attach dropdown class
  if (opts.dropdownClass) {
    if (Array.isArray(opts.dropdownClass)) {
      dropdown.classList.add(...opts.dropdownClass);
    } else {
      dropdown.classList.add(opts.dropdownClass);
    }
  }
  _field.insertAdjacentElement("afterend", dropdown);

  // Event listeners
  _field.addEventListener("click", function (e) {
    if (createItems(_field, opts) === 0) {
      // Prevent show empty
      e.stopPropagation();
      clearAutocomplete(_field);
      // Hide dropdown (implement based on your dropdown library)
    }
  });

  _field.addEventListener("keyup", function (e) {
    if (createItems(_field, opts) > 0) {
      // Show dropdown (implement based on your dropdown library)
    } else {
      // Sets up positioning
      _field.dispatchEvent(new Event("click"));
    }

    if (e.key === "Escape") {
      clearAutocomplete(_field);
    }
  });

  return el;
};
function expandClassArray(classes: string | string[]): string {
  if (typeof classes == "string") {
    return classes;
  }
  if (classes.length == 0) {
    return "";
  }
  return classes.join(" ");
}

export default autocompleteWithDividers;
