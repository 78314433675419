// import { createApp } from 'vue'
// import App from './App.vue'
import "./htmx-setup";
import "./htmx-antiforgery";
import "./client-validation";
import "./script/components/bootstrap-components-init.ts";
import "./script/components/no-auto-submit-on-enter.ts";
import "./script/components/file-upload.ts";
import "./script/components/quote-file-upload.ts";
import "./script/components/stop-propagation.ts";
import "./script/components/scroll-into-view";
import DatoCmsImage from "./script/components/datocms-image";
import ProductQuantityPicker from "./script/components/product-quantity-picker.ts";
import EditableField from "./script/components/editable-field.ts";
import Submit from "./script/components/submit.ts";
import "./script/components/stop-propagation.ts";
import Toaster, { getToastMessageType } from "./script/toaster.ts";
import Modal from "./script/components/modal.ts";
import "./script/pages/checkout-details.ts";
import "./script/components/scroll-into-view";
import "./script/utils/is-mobile.ts";
import QuickOrderModal from "./script/components/quick-order-modal.ts";
import "./script/components/quote-file-upload.ts";
import SearchAutoComplete from "./script/components/search-auto-complete.ts";
import MobileSearch from "./script/components/mobile-search.ts";
import "./script/components/search-scan.ts";

console.log("⚡ Loading main...");
// createApp(App).mount('#app')

document.body.addEventListener("htmx:afterRequest", function (evt) {
  // @ts-ignore
  const detail = evt.detail;
  const element = detail.elt;
  const xhr = detail.xhr as XMLHttpRequest;

  // re-init components on new element
  ProductQuantityPicker.init(element);
  DatoCmsImage.init();
  EditableField.init();
  Submit.init();
  MobileSearch.init(
    document.getElementById("offcanvasSearch"),
    document.getElementById("product-search--mobile-brand-button"),
    document.getElementById("product-search--mobile-search-term"),
    document.getElementById("offcanvasBrand"),
  );

  const mobileSearchForm = document.getElementById("product-search--mobile-form");
  if (isElementHidden(mobileSearchForm)) {
    SearchAutoComplete.init("product-search--search-term");
    MobileSearch.disable("product-search--search-term");
  }
  // show toast messages if needed
  const body = decode(xhr.getResponseHeader("X-Toast-Message"));
  const title = decode(xhr.getResponseHeader("X-Toast-Title"));
  const type = xhr.getResponseHeader("X-Toast-Type");
  const toastType = getToastMessageType(type);
  Toaster.show(body, title, toastType);

  // dismiss modal if needed
  const modalDismiss = xhr.getResponseHeader("X-Modal-Dismiss");
  if (modalDismiss === "true") {
    Modal.dismiss();
  }
});

document.body.addEventListener("htmx:afterProcessNode", (evt) => {
  let elt: HTMLElement | null;
  // @ts-ignore
  elt = evt.detail.elt;
  ProductQuantityPicker.init(elt);
  if (elt && elt?.classList.contains("js-quick-order-modal")) QuickOrderModal.init(elt);
});

function isElementHidden(element: HTMLElement | null) {
  return !element || window.getComputedStyle(element).display === "none";
}

function decode(encodedValue: string | null): string {
  const utf8Prefix = "utf-8''";

  if (!encodedValue) return "";
  if (encodedValue.startsWith(utf8Prefix)) {
    encodedValue = encodedValue.substring(utf8Prefix.length);
    const decodedValue = decodeURIComponent(encodedValue);
    return decodedValue;
  }
  return encodedValue;
}
