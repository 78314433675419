import {
  Camera,
  CameraSwitchControl,
  DataCaptureContext,
  DataCaptureView,
  FrameSourceState,
  RectangularViewfinder,
  RectangularViewfinderLineStyle,
  RectangularViewfinderStyle,
  configure,
} from "scandit-web-datacapture-core";
import {
  Barcode,
  BarcodeCapture,
  BarcodeCaptureOverlay,
  BarcodeCaptureOverlayStyle,
  BarcodeCaptureSession,
  BarcodeCaptureSettings,
  Symbology,
  SymbologyDescription,
  SymbologySettings,
  barcodeCaptureLoader,
} from "scandit-web-datacapture-barcode";
import $$ from "../utils/double-dollar";

declare global {
  interface Window {
    continueScanning: () => Promise<void>;
  }
}

export default class SearchScan {
  private barcodeCapture: BarcodeCapture | undefined;
  private barcodeCaptureOverlay: BarcodeCaptureOverlay | undefined;
  private viewfinder: RectangularViewfinder | undefined;
  constructor(
    private barcodePickerElement: HTMLElement,
    private containerElement: HTMLElement,
  ) {
    const scanditLicenseKey = this.barcodePickerElement.dataset.ntLicenseKey as string;
    // continue from here for the redirect to productpage task
    // const searchProductUrl = this.barcodePickerElement.dataset.ntSearchProductUrl as string;
    this.run(scanditLicenseKey);
  }

  async run(scanditLicenseKey: string): Promise<void> {
    const view = new DataCaptureView();
    view.connectToElement(this.containerElement);
    view.showProgressBar();
    await configure({
      licenseKey: scanditLicenseKey,
      libraryLocation: new URL(
        "https://cdn.jsdelivr.net/npm/scandit-web-datacapture-barcode@6.x/build/engine/",
      ).toString(),
      moduleLoaders: [barcodeCaptureLoader()],
    });
    view.setProgressBarPercentage(null);
    view.setProgressBarMessage("Accessing Camera...");

    // Create the data capture context.
    const context: DataCaptureContext = await DataCaptureContext.create();
    // To visualize the ongoing barcode capturing process on screen, attach the data capture view that renders the
    // camera preview. The view must be connected to the data capture context.
    await view.setContext(context);

    // Try to use the world-facing (back) camera and set it as the frame source of the context. The camera is off by
    // default and must be turned on to start streaming frames to the data capture context for recognition.
    const camera: Camera = Camera.default;
    const cameraSettings = BarcodeCapture.recommendedCameraSettings;
    await camera.applySettings(cameraSettings);
    await context.setFrameSource(camera);

    // The barcode capturing process is configured through barcode capture settings,
    // they are then applied to the barcode capture instance that manages barcode recognition.
    const settings: BarcodeCaptureSettings = new BarcodeCaptureSettings();

    // The settings instance initially has all types of barcodes (symbologies) disabled. For the purpose of this
    // sample we enable a very generous set of symbologies. In your own app ensure that you only enable the
    // symbologies that your app requires as every additional enabled symbology has an impact on processing times.
    settings.enableSymbologies([
      Symbology.EAN13UPCA,
      Symbology.EAN8,
      Symbology.EAN13UPCA,
      Symbology.UPCE,
      Symbology.QR,
      Symbology.DataMatrix,
      Symbology.Code39,
      Symbology.Code128,
      Symbology.InterleavedTwoOfFive,
    ]);

    // Some linear/1D barcode symbologies allow you to encode variable-length data. By default, the Scandit
    // Data Capture SDK only scans barcodes in a certain length range. If your application requires scanning of one
    // of these symbologies, and the length is falling outside the default range, you may need to adjust the "active
    // symbol counts" for this symbology. This is shown in the following few lines of code for one of the
    // variable-length symbologies.
    const symbologySettings: SymbologySettings = settings.settingsForSymbology(Symbology.Code39);
    symbologySettings.activeSymbolCounts = [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

    // Create a new barcode capture mode with the settings from above.
    const barcodeCapture = await BarcodeCapture.forContext(context, settings);
    // Disable the barcode capture mode until the camera is accessed.
    await barcodeCapture.setEnabled(false);

    // Add a control to be able to switch cameras.
    view.addControl(new CameraSwitchControl());

    // Add a barcode capture overlay to the data capture view to render the location of captured barcodes on top of
    // the video preview. This is optional, but recommended for better visual feedback.
    const barcodeCaptureOverlay: BarcodeCaptureOverlay = await BarcodeCaptureOverlay.withBarcodeCaptureForViewWithStyle(
      barcodeCapture,
      view,
      BarcodeCaptureOverlayStyle.Frame,
    );

    // Register a listener to get informed whenever a new barcode got recognized.
    barcodeCapture.addListener({
      didScan: async (_: BarcodeCapture, session: BarcodeCaptureSession) => {
        await barcodeCapture.setEnabled(false);
        // Hide the viewfinder.
        await barcodeCaptureOverlay.setViewfinder(null);
        const barcode: Barcode | null = session.newlyRecognizedBarcode;
        if (!barcode) {
          return;
        }
        const symbology: SymbologyDescription = new SymbologyDescription(barcode.symbology);

        // there s no data yet to test upon, continue from here
        //console.debug("scanned", barcode.rawData);
        //console.debug("url", searchProductUrl);
        //window.location.href = `${searchProductUrl}?searchTerm=${encodeURI(barcode.rawData)}&source=scanner`;
        this.showResult(`Scanned: ${barcode.data ?? ""}\n(${symbology.readableName})`);
      },
    });

    this.viewfinder = new RectangularViewfinder(
      RectangularViewfinderStyle.Square,
      RectangularViewfinderLineStyle.Light,
    );
    await barcodeCaptureOverlay.setViewfinder(this.viewfinder);

    // Switch the camera on to start streaming frames.
    // The camera is started asynchronously and will take some time to completely turn on.
    await camera.switchToDesiredState(FrameSourceState.On);
    await barcodeCapture.setEnabled(true);
    view.hideProgressBar();
  }

  static init(barcodePickerClassIdentifier: string, barcodePickerContainerIdentifier: string) {
    $$(barcodePickerClassIdentifier, (barcodePickerElement: HTMLElement) => {
      const barcodePickerContainerElement = document.getElementsByClassName(
        barcodePickerContainerIdentifier,
      )[0] as HTMLElement | null;

      if (barcodePickerElement && barcodePickerContainerElement)
        new SearchScan(barcodePickerElement, barcodePickerContainerElement);
    });
  }

  async continueScanning(): Promise<void> {
    for (const r of document.querySelectorAll(".result")!) {
      r.querySelector("button")?.removeEventListener("click", this.continueScanning);
      r.remove();
    }
    await this.barcodeCapture!.setEnabled(true);
    await this.barcodeCaptureOverlay!.setViewfinder(this.viewfinder!);
  }
  showResult(result: string): void {
    const resultElement = document.createElement("div");
    resultElement.className = "result";

    const paragraph = document.createElement("p");
    paragraph.classList.add("result-text");

    const button = document.createElement("button");
    button.textContent = "OK";
    button.addEventListener("click", this.continueScanning, { once: true });

    resultElement.append(paragraph, button);
    resultElement.querySelector(".result-text")!.textContent = result;
    document.body.append(resultElement);
  }
}

SearchScan.init(".js-search-scan-barcode-picker", "js-search-scan-page");
